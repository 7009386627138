import { $themeBreakpoints } from '@themeConfig'
import axios from 'axios'
// import { stringify } from 'uuid'
import store from '@/store'
import rpc from '@/auth/rpc/ociRpc'
import router from '@/router'
import Configuration from '@/utils/configuration'

function padTimeElement(val) {
  if (val.toString().length > 1) {
    return val
  }

  const newVal = `0${val.toString()}`
  return newVal
}

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    loggedIn: false,
    currentDateHeading: '',
    checkedInStatus: '',
    isLoadingAssigments: false,
    isLoadingBooking: false,
    isCheckInOut: true,
    isFromProjectListPage: false,
    getNextJob: false,
    userName: '',
    DriverId: -1,
    ethosURL: [],
    ethosPos: 0,
    ethosLen: 0,
    userAssignmentsID: -2,
    driverBookingID: -2,
    previousAssignmentsID: -1,
    userAssignmentsIDList: [-1],
    userAssignmentSet: false,
    driverBookingSet: false,
    userAllowAcceptReject: true,
    totalAssignments: 0,
    siteCount: 0,
    userDetails: {},
    currentSiteOperativeId: -1,
    signInTime: '',
    currentSiteDetails: {
      name: '',
      address: '',
      postcode: '',
      latitude: -1,
      longitude: -1,
      jobNumber: '',
      AssignNumber: '',
      jobState: '',
      contactName: '',
      contactPhone: '',
      openTime: '',
      closeTime: '',
      date: '',
    },
    siteDetailList: [],
    currentTask: '',
    projects: [],
    bookings: [],
    bookingDockets: [],
    wasteMaterials: [],
    wasteLocations: [],
    bookingLines: [],
    dcLineTypes: [],
    notifications: [],
    currentBookingData: {
      id: -1,
      job: -1,
      client_name: '',
      po_number: '',
      carrier: -1,
      collection_method: -1,
      vehicle_type: -1,
      dockets: [],
      driver: null,
      booked_for: '',
      site_main_address: '',
      site_postal_code: '',
      status: '',
      notes: '',
      contact_name: '',
      contact_phone_or_mobile: '',
      type: '',
      reason: '',
      reason_notes: '',
      print_name: '',
      signature: null,
      supporting_documents: [],
      items_to_collect: true,
    },
    hasCurrentBooking: false,
  },

  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getLoggedIn(state) {
      return state.loggedIn
    },
    getCurrentDateHeading(state) {
      return state.currentDateHeading
    },
    getUserName(state) {
      return state.userName
    },
    getDriverId(state) {
      console.log('getDriverId', state.DriverId)
      return state.DriverId
    },
    getUserEmail(state) {
      return state.userEmail
    },
    getEthosURL(state) {
      return state.ethosURL
    },
    getEthosPos(state) {
      return state.ethosPos
    },
    getEthosLen(state) {
      return state.ethosLen
    },
    getSignInTime(state) {
      return state.signInTime
    },
    getUserAssignmentsID(state) {
      return state.userAssignmentsID
    },
    getDriverBookingID(state) {
      return state.driverBookingID
    },
    getUserAssignmentsIDList(state) {
      return state.userAssignmentsIDList
    },
    getTotalAssignments(state) {
      return state.totalAssignments
    },
    getUserDetails(state) {
      return state.userDetails
    },
    getCurrentSiteOperativeId(state) {
      return state.currentSiteOperativeId
    },
    getCurrentSiteOperativeId2(state) {
      return state.currentSiteOperativeId2
    },
    getCurrentSiteDetails(state) {
      return state.currentSiteDetails
    },
    getSiteDetailsList(state) {
      return state.siteDetailList
    },
    getCurrentTask(state) {
      return state.currentTask
    },
    getCheckedInStatus(state) {
      return state.checkedInStatus
    },
    getCheckedInStatus2(state) {
      return state.checkedInStatus2
    },
    getIsLoadingAssigments(state) {
      return state.isLoadingAssigments
    },
    getIsLoadingBooking(state) {
      return state.isLoadingBooking
    },
    getIsCheckInOut(state){
      return state.isCheckInOut
    },
    getIsFromProjectListPage(state){
      return state.isFromProjectListPage
    },
    getGetNextJob(state) {
      return state.getNextJob
    },
    getPreviousAssignmentsID(state){
      return state.previousAssignmentsID
    },
    getUserAssignmentSet(state) {
      return state.userAssignmentSet
    },    
    getOneAlreadyCheckedIn(state) {
      return state.oneAlreadyCheckedIn
    },   
    getProjectsList(state) {
      return state.projects
    },
    getDriversBookingsList(state) {
      return state.bookings
    },
    getNotificationsList(state) {
      return state.notifications
    },
    getAllowAcceptReject(state, val) {
      return state.userAllowAcceptReject
    },
    getCurrentBookingData(state) {
      console.log('getCurrentBookingData', state.currentBookingData)
      return state.currentBookingData
    },
    getBookingDocketDetails(state) {
      console.log('getBookingDocketDetails', state.bookingDockets)
      if (state.bookingDockets.length > 0) {
        return state.bookingDockets[0]
      }
      return null
    },
    getBookingLines(state) {
      console.log('getBookingLines', state.bookingLines)
      return state.bookingLines
      /*if (state.bookingLines.length > 0) {
        return state.bookingLines[0]
      }
      */
      //return null
    },
    getWasteMaterials(state) {
      return state.wasteMaterials
    },
    getWasteLocations(state) {
      return state.wasteLocations
    },
    getDCLineTypes(state) {
      console.log('getDCLineTypes', state.dcLineTypes)
      return state.dcLineTypes
    },
    getHasCurrentBooking(state) {
      return state.hasCurrentBooking
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    setLoggedIn(state) {
      state.loggedIn = rpc.getWebToken() !== null
    },
    setCurrentDateHeading(state) {
      const currentDate = new Date()
      const currentDay = currentDate.toLocaleString('default', { day: 'numeric' })
      // const currentDay = currentDate.getDay();
      const currentDayOfMonth = currentDate.toLocaleString('default', { weekday: 'long' })
      const currentMonth = currentDate.toLocaleString('default', { month: 'long' })
      state.currentDateHeading = `${currentDayOfMonth} ${currentDay} ${currentMonth}`
    },
    setCheckedInStatus(state, result) {
      if (result === 'No assignments') {
        state.checkedInStatus = result
        return
      }
      // If not checked in or invalid, set to not checked in/blank
      if (result === undefined || result.signed_in === 0 || result.signed_in === null || result.signed_in === undefined || result.signed_out === undefined || result.signed_out === null) {
        state.checkedInStatus = ''
        return
      }

      // Check in as checked in status is > 0 and checked out status is < 1
      if (result.signed_in > 0 && result.signed_out < 1) {
        state.checkedInStatus = 'in'
        return
      }

      // Getting here, the operative has checked out of the site
      state.checkedInStatus = 'out'
    },  
    setIsLoadingAssigments(state, val) {
      state.isLoadingAssigments = val
    },    
    setIsCheckInOut(state, val){
      state.isCheckInOut = val
    },
    setIsFromProjectListPage(state, val){
      state.isFromProjectListPage = val
    },
    setGetNextJob(state, val){
      state.getNextJob = val
    },    
    setPreviousAssignmentsID(state, val){
      state.previousAssignmentsID = val
    },
    updateCheckedInStatus(state, newStatus) {
      state.checkedInStatus = newStatus
    },
    setUserName(state, val) {
      state.userName = val
    },
    setDriverId(state, val) {
      console.log('setDriverId', val)
      state.DriverId = val
    },
    setUserEmail(state, val) {
      state.userEmail = val
    },
    setEthosURL(state, val) {
      state.ethosURL = val
      state.ethosPos = 0
      state.ethosLen = val.length
    },
    setEthosPos(state, val) {
      state.ethosPos = val
    },
    setSignInTime(state, val) {
      state.signInTime = val
    },    
    setUserAssignmentsID(state, val) {
      state.userAssignmentSet = false
      state.oneAlreadyCheckedIn = false
      if (val === undefined || val === null || val.length === 0) {
        state.userAssignmentsID = -10
        state.userAssignmentsIDList = [-1]
        state.totalAssignments = 0
        return
      }

      state.userAssignmentsID = val[0]

      state.userAssignmentsIDList = [-1] 
      
      for (let v=0; v<val.length; ++v) {
        state.userAssignmentsIDList[v] = val[v]
      }
      state.totalAssignments = val.length
      state.siteCount = 0

      // console.log(state.userAssignmentsIDList)
    },
    changeUserAssignmentsID(state, val) {
      state.userAssignmentsID = val
      state.userAssignmentSet = true
    },
    changeDriverBookingID(state, val) {
      console.log('changeDriverBookingID', val)
      state.driverBookingID = val
      state.driverBookingSet = true
    },
    setAllowAcceptReject(state, val) {
      state.userAllowAcceptReject = val
    },
    setUserDetails(state, val) {
      state.userDetails = val
    },
    setCurrentSiteOperativeId(state, val) {
      state.currentSiteOperativeId = val
    },
    setCurrentSiteOperativeId2(state, val) {
      state.currentSiteOperativeId2 = val
    },    
    setCurrentSiteDetails(state, val) {
      state.currentSiteDetails.name = val.customer_name
      state.currentSiteDetails.address = val.site_main_address
      state.currentSiteDetails.postcode = val.site_postal_code
      state.currentSiteDetails.latitude = val.site_latitude
      state.currentSiteDetails.longitude = val.site_longitude
      state.currentSiteDetails.jobNumber = val.site_number
      // state.currentSiteDetails.AssignNumber = val.number
      state.currentSiteDetails.jobState = val.status
      state.currentSiteDetails.contactName = val.contact_name
      state.currentSiteDetails.contactPhone = val.contact_value
//      console.log(val.start_time)


      //console.log(val.start_time.month)
      const localtimeSiteOpeningTime = new Date(Date.UTC(val.start_time.year, val.start_time.month - 1, val.start_time.day, val.site_opening_time.hour, val.site_opening_time.minute, 0))
//       console.log(localtimeSiteOpeningTime)
      const localtimeSiteClosingTime = new Date(Date.UTC(val.end_time.year, val.end_time.month - 1, val.end_time.day, val.site_closing_time.hour, val.site_closing_time.minute, 0))

      state.currentSiteDetails.openTime = `${padTimeElement(val.site_opening_time.hour)}:${padTimeElement(val.site_opening_time.minute)}`
      state.currentSiteDetails.closeTime = `${padTimeElement(val.site_closing_time.hour)}:${padTimeElement(val.site_closing_time.minute)}`


      // state.currentSiteDetails.openTime = `${padTimeElement(localtimeSiteOpeningTime.getHours())}:${padTimeElement(localtimeSiteOpeningTime.getMinutes())}`
      // state.currentSiteDetails.closeTime = `${padTimeElement(localtimeSiteClosingTime.getHours())}:${padTimeElement(localtimeSiteClosingTime.getMinutes())}`
      state.currentSiteDetails.date = val.start_time
    },
    setSiteCountZero(state, val) {
      state.siteCount = val
      // state.siteDetailList = []
    },          
    setSiteCount(state) {
      state.siteCount = state.siteCount + 1
    },      
    setSiteDetailsList(state, val) {
      let v = state.siteCount-1
      if (v < 0) {
        v = 0
      }
      state.userAssignmentSet = false
      const st = {
        assignmentIdIndex: -1,
        checkedInStatus: '',
        name: '',
        address: '',
        postcode: '',
        latitude: -1,
        longitude: -1,
        jobNumber: '',
        AssignNumber: '',
        jobState: '',
        contactName: '',
        contactPhone: '',
        openTime: '',
        closeTime: ''
      }
      st.assignmentIdIndex = v
      st.name = val.customer_name
      st.address = val.site_main_address
      st.postcode = val.site_postal_code
      st.latitude = val.site_latitude
      st.longitude = val.site_longitude
      st.jobNumber = val.site_number
      // st.AssignNumber = val.number
      st.jobState = val.status
      st.contactName = val.contact_name
      st.contactPhone = val.contact_value

      const localtimeSiteOpeningTime = new Date(Date.UTC(val.start_time.year, val.start_time.month, val.start_time.day, val.site_opening_time.hour, val.site_opening_time.minute, 0))
      const localtimeSiteClosingTime = new Date(Date.UTC(val.end_time.year, val.end_time.month, val.end_time.day, val.site_closing_time.hour, val.site_closing_time.minute, 0))
      st.openTime = `${padTimeElement(val.site_opening_time.hour)}:${padTimeElement(val.site_opening_time.minute)}`
      st.closeTime = `${padTimeElement(val.site_closing_time.hour)}:${padTimeElement(val.site_closing_time.minute)}`
      // st.openTime = `${padTimeElement(localtimeSiteOpeningTime.getHours())}:${padTimeElement(localtimeSiteOpeningTime.getMinutes())}`
      // st.closeTime = `${padTimeElement(localtimeSiteClosingTime.getHours())}:${padTimeElement(localtimeSiteClosingTime.getMinutes())}`

      if (val === 'No assignments') {
        st.checkedInStatus = val
      } else if (val === undefined || val.signed_in === 0 || val.signed_in === null || val.signed_in === undefined || val.signed_out === undefined || val.signed_out === null) {
        st.checkedInStatus = ''
      } else if (val.signed_in > 0 && val.signed_out < 1) {
        st.checkedInStatus = 'in'
        state.oneAlreadyCheckedIn = true
      } else {
        st.checkedInStatus = 'out'
        state.oneAlreadyCheckedIn = true
      }

      state.siteDetailList[v] = st
      // console.log(state.siteDetailList)
    },
    setCurrentTask(state, val) {
      state.currentTask = val
    },
    setUserAssignmentSet(state, val) {
      state.userAssignmentSet = val
    },
    setCurrentBookingData(state, val) {
      console.log('val', val)
      console.log('val.id', val.id, val.job)
      state.currentBookingData.id = val.id
      state.currentBookingData.job = val.job_number
      state.currentBookingData.client_name = val.client_name
      state.currentBookingData.po_number = val.po_number
      state.currentBookingData.carrier = val.carrier
      state.currentBookingData.collection_method = val.collection_method
      state.currentBookingData.vehicle_type = val.vehicle_type
      state.currentBookingData.dockets = val.dockets
      state.currentBookingData.booked_for = val.booked_for
      state.currentBookingData.site_main_address = val.site_main_address
      state.currentBookingData.site_postal_code = val.site_postal_code
      state.currentBookingData.status = val.status
      state.currentBookingData.notes = val.notes
      state.currentBookingData.contact_name = val.contact_name
      state.currentBookingData.contact_phone_or_mobile = val.contact_phone_or_mobile
      state.currentBookingData.type = val.type
      state.currentBookingData.reason = val.reason
      state.currentBookingData.reason_notes = val.reason_notes
      state.currentBookingData.items_to_collect = true
      // state.currentBookingData.items_to_collect = val.items_to_collect
      state.currentBookingData.print_name = val.print_name
      state.currentBookingData.signature = val.signature

      state.currentBookingData.supporting_documents = []

      for (let j = 0; j < val['supporting_documents.'].length; ++j) {
        const sd = val['supporting_documents.'][j]
        state.currentBookingData.supporting_documents.push({
          id: sd.id,
          name: sd.name,
          data: sd.data,
        })
      }

      console.log('setCurrentBookingData', state.currentBookingData)
    },
    setHasCurrentBooking(state, val) {
      state.hasCurrentBooking = val
    },
    clearProjectsList(state, val) {
      state.projects = val
    },
    clearBookingsList(state, val) {
      state.bookings = val
    },
    clearBookingDocketDetails(state, val) {
      state.bookingDockets = val
    },
    setCurrentBookingItemsToCollect(state, val) {
      console.log('setCurrentBookingItemsToCollect', val)
      //state.bookingDockets.items_to_collect = val
    },
    clearWasteMaterials(state, val) {
      state.wasteMaterials = val
    },
    clearDCLineTypes(state, val) {
      state.dcLineTypes = val
    },
    clearWasteLocations(state, val) {
      state.wasteLocations = val
    },
    clearNotificationsList(state, val) {
      state.notifications = val
    },
    clearHasCurrentBooking(state, val) {
      state.hasCurrentBooking = val
    },
    clearDCBookingLines(state, val) {
      state.bookingLines = val
    },
    setProjectsList(state, val) {
      state.projects = []
      for (let i=0; i<val.length; ++i) {
        const st = {
          id: -1,
          date: '',
          site_main_address: '',
          job: [
            { state:'', site_main_address: '', site_postal_code: '' },
          ]
        }
        st.id = val[i].id
        st.date = val[i].start_time
        st.job.site_main_address = val[i].site_main_address
        st.job.site_postal_code = val[i].site_postal_code

        /*
        if (val[i].signed_out == 1) {
          st.job.state = 'completed'
        }else if (val[i].signed_in == 1) {
          st.job.state = 'Signed In'
        }else{
          st.job.state = val[i].status
        }
        */
       
        if (val[i].status == 'attended') {
          st.job.state = 'completed'
        //}else if (val[i].signed_in == 1) {
        //  st.job.state = 'Signed In'
        }else{
          st.job.state = val[i].status
        }

        // st.job.state = val[i].status
        state.projects[i] = st
      }
    },
    setBookingList(state, val) {
      state.bookings = []
      for (let i = 0; i < val.length; ++i) {
        const st = {
          id: -1,
          job: -1,
          client_name: '',
          booked_for: '',
          po_number: '',
          carrier: -1,
          collection_method: -1,
          dockets: [],
          vehicle_type: -1,
          driver: null,
          site_main_address: '',
          site_postal_code: '',
          status: 'completed',
          contact_name: '',
          contact_phone_or_mobile: '',
          print_name: '',
          signature: null,
          items_to_collect: true,
          type: '',
        }
        st.id = val[i].id
        st.job = val[i].job_number
        st.client_name = val[i].client_name
        st.booked_for = val[i].booked_for
        st.po_number = val[i].po_number
        st.carrier = val[i].carrier
        st.collection_method = val[i].collection_method
        st.dockets = val[i].dockets
        st.vehicle_type = val[i].vehicle_type
        st.driver = val[i].driver
        st.site_main_address = val[i].site_main_address
        st.site_postal_code = val[i].site_postal_code
        st.status = val[i].status
        st.contact_name = val[i].contact_name
        st.contact_phone_or_mobile = val[i].contact_phone_or_mobile
        st.type = val[i].type

        state.bookings[i] = st
      }
      console.log('setBookingList', state.bookings)
    },
    setBookingDocketDetails(state, val) {
      state.bookingDockets = []
      for (let i = 0; i < val.length; ++i) {
        const st = {
          id: -1,
          collection: -1,
          job: -1,
          notes: '',
          location: -1,
          carrier_company: -1,
          actual_weight: 0,
          items: [],
          supporting_documents: [],
          // bins: [],
          docket_number: '',
          additional_information: '',
          driver_print_name: '',
          client_print_name: '',
          waste_docket_number: '',
          vehicle_registration_number: '',
          client_not_available: false,
          client_signature: null,
        }

        st.id = val[i].id
        st.collection = val[i].collection
        st.job = val[i].job
        st.notes = val[i].notes
        st.location = val[i].location
        st.carrier_company = val[i].carrier_company
        st.actual_weight = val[i].actual_weight
        st.client_not_available = val[i].client_not_available
        st.client_signature = val[i].client_signature

        for (let j = 0; j < val[i]['items.'].length; ++j) {
          const item = val[i]['items.'][j]
          let bins = 0
          let bin_type = ''

          if (item.number_of_240_bins > 0) {
            bins = item.number_of_240_bins
            bin_type = '240'
          } else if (item.number_of_360_bins > 0) {
            bins = item.number_of_360_bins
            bin_type = '360'
          } else if (item.number_of_660_bins > 0) {
            bins = item.number_of_660_bins
            bin_type = '660'
          }

          st.items.push({
            id: item.id,
            material: item.material,
            number_of_240_bins: item.number_of_240_bins,
            number_of_360_bins: item.number_of_360_bins,
            number_of_660_bins: item.number_of_660_bins,
            number_of_bins: item.number_of_bins,
            bins: bins,
            bin_type: bin_type,
            isVisible: true,
          })
        }
        if (val[i]['items.'].length === 0) {
          st.items.push({
            id: -1,
            material: -1,
            number_of_240_bins: 0,
            number_of_360_bins: 0,
            number_of_660_bins: 0,
            number_of_bins: 0,
            bins: 0,
            bin_type: '240',
            isVisible: true,
          })
        }

        //st.items = val[i]['items.']
        st.docket_number = val[i].docket_number
        st.additional_information = val[i].additional_information
        st.driver_print_name = val[i].driver_print_name
        st.client_print_name = val[i].client_print_name
        st.waste_docket_number = val[i].waste_docket_number
        st.vehicle_registration_number = val[i].vehicle_registration_number

        for (let j = 0; j < val[i]['supporting_documents.'].length; ++j) {
          const sd = val[i]['supporting_documents.'][j]

          st.supporting_documents.push({
            id: sd.id,
            name: sd.name,
            data: sd.data,
          })
        }

        state.bookingDockets[i] = st
      }
      console.log('setBookingDocketDetails', state.bookingDockets)
    },
    setWasteMaterials(state, val) {
      console.log('setWasteMaterials')
      state.wasteMaterials = []
      for (let i = 0; i < val.length; ++i) {
        const st = {
          id: -1,
          name: '',
          ewc: '',
          recyclable_percentage: '',
          is_hazardous: '',
          weight_per_bin_240: '',
          weight_per_bin_360: '',
          weight_per_bin_660: '',
        }
        st.id = val[i].id
        st.name = val[i].name
        st.ewc = val[i].ewc
        st.recyclable_percentage = val[i].recyclable_percentage
        st.is_hazardous = val[i].is_hazardous
        st.weight_per_bin_240 = val[i].weight_per_bin_240
        st.weight_per_bin_360 = val[i].weight_per_bin_360
        st.weight_per_bin_660 = val[i].weight_per_bin_660

        state.wasteMaterials[i] = st
      }
      console.log('setWasteMaterials', state.wasteMaterials)
    },
    setWasteLocations(state, val) {
      state.wasteLocations = []
      for (let i = 0; i < val.length; ++i) {
        const st = {
          id: -1,
          address: '',
          address_name: '',
          carrier: '',
          managed_by: '',
          ea_permit_number: '',
        }
        st.id = val[i].id
        st.address = val[i].address
        st.address_name = val[i].address_name
        st.carrier = val[i].carrier
        st.managed_by = val[i].managed_by
        st.ea_permit_number = val[i].ea_permit_number

        state.wasteLocations[i] = st
      }
      console.log('setWasteLocations', state.wasteLocations)
    },
    setDCLineTypes(state, val) {
      state.dcLineTypes = []
      for (let i = 0; i < val.length; ++i) {
        const st = {
          id: -1,
          name: '',
          category: null,
          trackable_by_quantity: null,
        }
        st.id = val[i].id
        st.name = val[i].name
        st.category = val[i].category
        st.trackable_by_quantity = val[i].trackable_by_quantity

        state.dcLineTypes[i] = st
      }
    },
    setDCBookingLines(state, val) {
      state.bookingLines = []
      for (let i = 0; i < val.length; ++i) {
        const st = {
          id: -1,
          quantity: -1,
          notes: '',
          is_collection: null,
          type: null,
          isVisible: true,
        }

        st.id = val[i].id
        st.quantity = val[i].quantity
        st.notes = val[i].notes
        st.is_collection = val[i].is_collection
        if (val[i]['type.']) {
          st.type = {
            id: val[i]['type.'].id,
            name: val[i]['type.'].name,
            category: val[i]['type.'].category,
            trackable_by_quantity: val[i]['type.'].trackable_by_quantity,
          }
        } else {
          st.type = {
            id: -1,
            name: '',
            category: '',
            trackable_by_quantity: null,
          }
        }

        state.bookingLines[i] = st
      }
      console.log('setDCBookingLines', state.bookingLines)
    },
    clearNotificationsList(state) {
      state.notifications = []
    },
    setNotificationsList(state, val) {
      state.notifications = []
      var index = 0;
      for (let i=0; i<val.length; ++i) {
        // console.log(val[i]['resource.'].id) 
        // const create_date = new Date(val[i].create_date.year, val[i].create_date.month-1, val[i].create_date.day, val[i].create_date.hour, val[i].create_date.minute, val[i].create_date.second)
        // const write_date = new Date()
        // if (val[i].write_date) {
        //  write_date = new Date(val[i].write_date.year, val[i].write_date.month-1, val[i].write_date.day, val[i].write_date.hour, val[i].write_date.minute, val[i].write_date.second)
        // }
        if (val[i]['resource.']) {
          const st = {
            notification_id: -1,
            id: -1,
            date: '',
            site_main_address: ''
          }
          st.notification_id = val[i].id
          st.id = val[i]['resource.'].id
          st.date = val[i]['resource.'].start_time
          st.site_main_address = val[i]['resource.'].site_main_address

          state.notifications[index] = st
          index = index + 1
        }

      }
    },
    clearToken(state) {
      console.log('logging out')
      state.loggedIn = false
      rpc.setToken('')
      rpc.setRefreshToken('')
      state.currentJob = {}
      state.currentDateHeading = ''
      state.checkedInStatus = ''
      state.currentTask = ''
      state.userName = ''
      state.ethosURL = []
      state.ethosPos = 0
      state.ethosLen = 0
      state.userAssignmentsID = -20
      state.userDetails = {}
      state.currentSiteOperativeId = -1
      state.currentSiteDetails.name = ''
      state.currentSiteDetails.address = ''
      state.currentSiteDetails.postcode = ''
      state.currentSiteDetails.latitude = -1
      state.currentSiteDetails.longitude = -1
      state.currentSiteDetails.jobNumber = ''
      state.currentSiteDetails.AssignNumber = ''
      state.currentSiteDetails.jobState = ''
      state.currentSiteDetails.contactName = ''
      state.currentSiteDetails.contactPhone = ''
      state.currentSiteDetails.openTime = ''
      state.currentSiteDetails.closeTime = ''
      state.userAssignmentSet = false
      state.oneAlreadyCheckedIn = false
      state.notifications = []

      // state.accessExpiry = 0
      // state.refreshExpiry = 0
      // if (state.accessTimer !== null) {
      //   clearTimeout(state.accessTimer)
      //   state.accessTimer = null
      // }
      // if (state.refreshTimer !== null) {
      //   clearTimeout(state.refreshTimer)
      //   state.refreshTimer = null
      // }
    },
  },
  actions: {
    logoutAndReset(vuexContext) {
      vuexContext.commit('clearToken')
      router.push('/login')
    },
    async getCurrentJobForCurrentDay(vuexContext, assignmentID) {
      console.log('getCurrentJobForCurrentDay')
      const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
      const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        id: '1',
        // method: 'model.site.operative.assignment.search_read',
        method: 'model.project.site.assignment.search_read',
        params: [[['id', '=', assignmentID]], null, null, null, ['operative.id', 'operative.rec_name', 'operative_name', 'site_name', 'site_main_address', 'site_postal_code', 'site_latitude', 'site_longitude', 'contact_name', 'contact_value', 'start_time', 'end_time', 'role_name', 'signed_in', 'signed_out', 'status', 'site_opening_time', 'site_closing_time', 'site_number', 'customer_name'], { web_user_session: rpc.getWebToken() }], // 'number','site_operative',c
      }

      try {
        const response = await axios.post(path, payload, config)
        if (response) {
          if (response.data.error !== undefined) {
            return
          }

          if (response.data.result.length > 0) {
            vuexContext.commit('setCheckedInStatus', response.data.result[0])
            // vuexContext.commit('setUserName', response.data.result[0].operative_name)
            vuexContext.commit('setCurrentSiteOperativeId', response.data.result[0].id) // site_operative
            vuexContext.commit('setCurrentSiteDetails', response.data.result[0])
            vuexContext.commit('setCurrentTask', response.data.result[0].role_name)
            return
          }

          vuexContext.commit('setCheckedInStatus', 'No assignments')
        }
      } catch (Exception) {
        console.log('getCurrentJobForCurrentDay catch:', Exception)
      }
      /*
      axios.post(path, payload, config)
        .then(response => {
          // console.log('assignment(getCurrentJobForCurrentDay): ', response)
          if (response.data.error !== undefined) {
            return
          }

          if (response.data.result.length > 0) {
            vuexContext.commit('setCheckedInStatus', response.data.result[0])
            // vuexContext.commit('setUserName', response.data.result[0].operative_name)
            vuexContext.commit('setCurrentSiteOperativeId', response.data.result[0].id) // site_operative
            vuexContext.commit('setCurrentSiteDetails', response.data.result[0])
            vuexContext.commit('setCurrentTask', response.data.result[0].role_name)
            return
          }

          vuexContext.commit('setCheckedInStatus', 'No assignments')
        })
        .catch(error => {
          console.log('getCurrentJobForCurrentDay catch:', error)
        })
        */
    },
    async getCurrentAssignment(vuexContext) {
      console.log('getCurrentAssignment')
      vuexContext.commit('clearHasCurrentBooking', false)
      const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
      const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        id: '1',
        method: 'model.project.site.assignment.search_read',
        params: [[['current_assignment', '!=', null]], null, 1, [['current_assignment', 'ASC']], ['operative.id', 'operative.rec_name', 'operative_name', 'site_name', 'site_main_address', 'site_postal_code', 'site_latitude', 'site_longitude', 'contact_name', 'contact_value', 'start_time', 'end_time', 'role_name', 'signed_in', 'signed_out', 'status', 'site_opening_time', 'site_closing_time', 'site_number', 'customer_name'], { web_user_session: rpc.getWebToken() }],
      }

      try {
        const response = await axios.post(path, payload, config)
        if (response) {
          if (response.data.error !== undefined) {
            return
          }

          if (response.data.result.length > 0) {
            vuexContext.commit('setCheckedInStatus', response.data.result[0])
            vuexContext.commit('setCurrentSiteOperativeId', response.data.result[0]['operative.'].id) // site_operative
            vuexContext.commit('setCurrentSiteDetails', response.data.result[0])
            vuexContext.commit('setCurrentTask', response.data.result[0].role_name)
            vuexContext.commit('changeUserAssignmentsID', response.data.result[0].id)
            vuexContext.commit('setIsLoadingAssigments', false)
            return
          }
          vuexContext.commit('setIsLoadingAssigments', false)
          vuexContext.commit('setCheckedInStatus', 'No assignments')

          await vuexContext.dispatch('getCurrentBooking')
        }
      } catch (Exception) {
        console.log('getCurrentAssignment catch:', Exception)
      }
    },
    async getFutureAssignment(vuexContext) {
      console.log('getFutureAssignment')
      const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
      const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        id: '1',
        method: 'model.project.site.assignment.search_read',
        params: [[['future_assignment', '=', true]], null, 1, [['start_time', 'ASC']], ['operative.id', 'operative.rec_name', 'operative_name', 'site_name', 'site_main_address', 'site_postal_code', 'site_latitude', 'site_longitude', 'contact_name', 'contact_value', 'start_time', 'end_time', 'role_name', 'signed_in', 'signed_out', 'status', 'site_opening_time', 'site_closing_time', 'site_number', 'customer_name'], { web_user_session: rpc.getWebToken() }],
      }

      try {
        const response = await axios.post(path, payload, config)
        if (response) {
          if (response.data.error !== undefined) {
            return
          }

          if (response.data.result.length > 0) {
            vuexContext.commit('setCheckedInStatus', response.data.result[0])
            vuexContext.commit('setCurrentSiteOperativeId', response.data.result[0]['operative.'].id) // site_operative
            vuexContext.commit('setCurrentSiteDetails', response.data.result[0])
            vuexContext.commit('setCurrentTask', response.data.result[0].role_name)
            vuexContext.commit('changeUserAssignmentsID', response.data.result[0].id)
            vuexContext.commit('setIsLoadingAssigments', false)
            return
          }

          vuexContext.commit('setCheckedInStatus', 'No assignments')
        }
      } catch (Exception) {
        console.log('getFutureAssignment catch:', Exception)
      }
    },
    getJobsListForCurrentDay(vuexContext, assignmentsIDList) {
      console.log('getJobsListForCurrentDay')
      const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
      const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      vuexContext.commit('setSiteCountZero', 0)
      console.log(assignmentsIDList)
      for (let v = 0; v < assignmentsIDList.length; ++v) {
        const payload = {
          id: '1',
          // method: 'model.site.operative.assignment.search_read',
          method: 'model.project.site.assignment.search_read',
          params: [[['id', '=', assignmentsIDList[v]]], null, null, null, ['operative.id', 'operative.rec_name', 'operative_name', 'site_name', 'site_main_address', 'site_postal_code', 'site_latitude', 'site_longitude', 'contact_name', 'contact_value', 'start_time', 'end_time', 'role_name', 'signed_in', 'signed_out', 'status', 'site_opening_time', 'site_closing_time', 'site_number', 'customer_name'], { web_user_session: rpc.getWebToken() }], // , 'number', 'site_operative'
        }

        axios.post(path, payload, config)
          .then(response => {
            console.log('assignment(list): ', response)
            if (response.data.error !== undefined) {
              return
            }

            if (response.data.result.length > 0) {
              vuexContext.commit('setSiteCount')
              // console.log('setSiteDetailsList')
              vuexContext.commit('setSiteDetailsList', response.data.result[0])
              return
            }
          })
          .catch(error => {
            console.log('getCurrentJobForCurrentDay catch:', error)
          })
      }
    },
    getJobsList(vuexContext, dateFrom) { // , ordertype
      console.log('getJobsList i')
      const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
      const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      // console.log(ordertype)
      const date1 = new Date(dateFrom)

      // const date2 = new Date(dateFrom)
      // date1.setDate(date1.getDate() - 700);
      // date2.setDate(date1.getDate() + 700);

      // console.log(date1)
      // console.log(date2)

      const dayFrom = date1.toLocaleString('default', { day: 'numeric' })
      const monthFrom = date1.toLocaleString('default', { month: 'numeric' })
      const yearFrom = date1.toLocaleString('default', { year: 'numeric' })

      // const hourFrom = date1.toLocaleString('default', { hour: 'numeric' })
      // console.log(hourFrom)
      // const minuteFrom = date1.toLocaleString('default', { minute: 'numeric' })
      // console.log(minuteFrom)
      // const secondFrom = date1.toLocaleString('default', { second: 'numeric' })
      // console.log(secondFrom)

      // const dayTo = date2.toLocaleString('default', { day: 'numeric' })
      // const monthTo = date2.toLocaleString('default', { month: 'numeric' })
      // const yearTo = date2.toLocaleString('default', { year: 'numeric' })

      // const filter = ['start_time', '>=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}]

      const nowDate = new Date()
      const dayNow = nowDate.toLocaleString('default', { day: 'numeric' })
      const monthNow = nowDate.toLocaleString('default', { month: 'numeric' })
      const yearNow = nowDate.toLocaleString('default', { year: 'numeric' })

      const filter = ['OR', [['start_time', '>=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}], ['end_time', '<', {"__class__": "datetime", "year": parseInt(yearNow), "month": parseInt(monthNow), "day": parseInt(dayNow), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}], ['status', 'in', ['attended','on_site']]], [['end_time', '>', {"__class__": "datetime", "year": parseInt(yearNow), "month": parseInt(monthNow), "day": parseInt(dayNow), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}]]]      

      // const filter = null

      const offset = 0
      const limit = 60
      const order = [['start_time', 'ASC']] // 'DESC' // ,['time', 'ASC']

      const payload = {
        id: '1',
        // method: 'model.site.operative.assignment.search_read',
        method: 'model.project.site.assignment.search_read',
        params: [[filter], offset, limit, order, ['operative.id', 'operative.rec_name', 'operative_name', 'site_name', 'site_main_address', 'site_postal_code', 'site_latitude', 'site_longitude', 'contact_name', 'contact_value', 'start_time', 'end_time', 'role_name', 'signed_in', 'signed_out', 'status', 'site_opening_time', 'site_closing_time', 'site_number', 'create_date', 'write_date', 'customer_name'], { web_user_session: rpc.getWebToken() }], // 'number',
      }

      // const payload = {
      //  id: '1',
      //  method: 'model.site.operative.assignment.search_read',
      //  params: [[['date', '>=', {"__class__": "date", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom)}], ['date', '<=', {"__class__": "date", "year": parseInt(yearTo), "month": parseInt(monthTo), "day": parseInt(dayTo)}]], null, null, order, ['operative.id', 'operative.rec_name', 'operative_name', 'site_name', 'site_main_address', 'site_postal_code', 'site_latitude', 'site_longitude', 'contact_name', 'contact_value', 'date', 'role_name', 'signed_in', 'signed_out', 'state', 'number', 'site_opening_time', 'site_closing_time', 'site_operative', 'site_number'], { web_user_session: rpc.getWebToken() }],
      // }

      // site.operative.assignment
      // const payload = {
      //  id: '1',
      //  method: 'model.site.operative.assignment.accept',
      //  params: [[7], { web_user_session: rpc.getWebToken() }],
      // }      
      axios.post(path, payload, config)
        .then(response => {
          vuexContext.commit('clearProjectsList', [])
          if (response.data.error !== undefined) {
            return
          }

          if (response.data.result.length > 0) {
            console.log(response.data.result)
            vuexContext.commit('setProjectsList', response.data.result)
           // vuexContext.commit('setNotificationsList', response.data.result)
            return
          }

        })
        .catch(error => {
          console.log('getJobsList catch:', error)
        })
    }, 
    getNewNotificationList(vuexContext) { // , ordertype
      const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
      const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      // const date1 = new Date(dateFrom)

      // const dayFrom = date1.toLocaleString('default', { day: 'numeric' })
      // const monthFrom = date1.toLocaleString('default', { month: 'numeric' })
      // const yearFrom = date1.toLocaleString('default', { year: 'numeric' })


      // const filter = ['start_time', '>=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}]
      // const offset = 0
      // const limit = 60
      // const order = [['start_time', 'ASC']] 

      // const payload = {
      //  id: '1',
      //  method: 'model.project.site.assignment.search_read',
      //  params: [[filter], offset, limit, order, ['operative.id', 'operative.rec_name', 'operative_name', 'site_name', 'site_main_address', 'site_postal_code', 'site_latitude', 'site_longitude', 'contact_name', 'contact_value', 'start_time', 'end_time', 'role_name', 'signed_in', 'signed_out', 'status', 'site_opening_time', 'site_closing_time', 'site_number', 'create_date', 'write_date', 'customer_name'], { web_user_session: rpc.getWebToken() }], //'number', , 'site_operative'
      // } 
      const filter = [["notified", "=", false], ["resource.id", "!=", null, "project.site.assignment"]]
      const limit = 10
      const payload = {
        id: '1',
        method: 'model.procentral.notification.search_read',
        params: [[filter], null, limit, null, ['resource.id', 'resource.site_main_address', 'resource.start_time'], { web_user_session: rpc.getWebToken() }], 
      } 
      axios.post(path, payload, config)
        .then(response => {
          // console.log('notifications')
          console.log(response)
          // vuexContext.commit('clearNotificationsList', [])
          if (response.data.error !== undefined) {
            return
          }

          if (response.data.result.length > 0) {
            vuexContext.commit('setNotificationsList', response.data.result)
            return
          }else{
            vuexContext.commit('clearNotificationsList')
            return
          }

        })
        .catch(error => {
          console.log('getNewNotificationList catch:', error)
        })
    },     
    NotificationSeen(vuexContext, notifications) {
      const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
      const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }

      var notificationIds = []
      for (var i = 0; i < notifications.length; ++i){
        notificationIds[i] = notifications[i].notification_id  
      }
      
      console.log(notificationIds)

      const payload = {
        id: '1',
        method: 'model.procentral.notification.notification_seen',
        params: [notificationIds, {web_user_session: rpc.getWebToken()}]
      }
  
      axios.post(path, payload, config)
      .then(response => {
        console.log('response notification seen')
        console.log(response)
      })
      .catch(error => {
        console.log('Notification Seen catch:', error)
      })
    },

    getBookingsList(vuexContext, dateFrom) {
      const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
      const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const date1 = new Date(dateFrom)

      const dayFrom = date1.toLocaleString('default', { day: 'numeric' })
      const monthFrom = date1.toLocaleString('default', { month: 'numeric' })
      const yearFrom = date1.toLocaleString('default', { year: 'numeric' })

      const driverId = store.getters['app/getDriverId']

      const filter = [['driver', '=', driverId], ['booked_for', '!=', null],
                ['booked_for', '>=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}]
                      ]

      const offset = 0
      const limit = 30
      const order = [['booked_for', 'DESC']]

      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.search_read',
        params: [filter, offset, limit, order, ['job_number', 'client_name', 'po_number', 'contact_name', 'contact_phone_or_mobile', 'carrier', 'collection_method', 'vehicle_type', 'dockets', 'driver', 'booked_for', 'site_main_address', 'site_postal_code', 'status', 'type'], { web_user_session: rpc.getWebToken() }],
      }
      axios.post(path, payload, config)
        .then(response => {
          vuexContext.commit('clearBookingsList', [])
          if (response.data.error !== undefined) {
            return
          }

          if (response.data.result.length > 0) {
            console.log('result', response.data.result)
            vuexContext.commit('setBookingList', response.data.result)
            return
          }

        })
        .catch(error => {
          console.log('getBookingList catch:', error)
        })
    },

    async getCurrentBooking(vuexContext) {
      const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
      const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const nowDate = new Date()

      const dayFrom = nowDate.toLocaleString('default', { day: 'numeric' })
      const monthFrom = nowDate.toLocaleString('default', { month: 'numeric' })
      const yearFrom = nowDate.toLocaleString('default', { year: 'numeric' })

      const driverId = store.getters['app/getDriverId']

      const filter = [['driver', '=', driverId], ['booked_for', '!=', null],
                ['booked_for', '>=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 0, "minute": 0, "second": 0, "microsecond": 0}],
                ['booked_for', '<=', {"__class__": "datetime", "year": parseInt(yearFrom), "month": parseInt(monthFrom), "day": parseInt(dayFrom), "hour": 23, "minute": 59, "second": 59, "microsecond": 0}],
                ['status', '!=', 'completed'],
                      ]

      const offset = 0
      const limit = 1
      const order = [['booked_for', 'ASC']]

      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.search_read',
        params: [filter, offset, limit, order, ['job_number', 'client_name', 'po_number', 'contact_name', 'contact_phone_or_mobile', 'carrier', 'collection_method', 'vehicle_type', 'dockets', 'driver', 'booked_for', 'site_main_address', 'site_postal_code', 'status', 'type'], { web_user_session: rpc.getWebToken() }],
      }
      axios.post(path, payload, config)
        .then(response => {
          vuexContext.commit('clearBookingsList', [])
          if (response.data.error !== undefined) {
            return
          }

          if (response.data.result.length > 0) {
            console.log('result', response.data.result)
            vuexContext.commit('setBookingList', response.data.result)
            vuexContext.commit('changeDriverBookingID', response.data.result[0].id)
            vuexContext.commit('setHasCurrentBooking', true)
          } else {
            vuexContext.commit('setHasCurrentBooking', false)
          }

        })
        .catch(error => {
          console.log('getCurrentBooking catch:', error)
        })
    },

    async getDriverBooking(vuexContext, bookingID) {
      console.log('getDriverBooking', bookingID)
      const path = process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/'
      const sAuth1 = String('admin:') + String(rpc.getUserId()) + String(':') + String(rpc.getToken())
      const sAuth = String(btoa(sAuth1))
      const sessionAuth = String('Session ') + sAuth
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: sessionAuth,
        },
      }
      const payload = {
        id: '1',
        method: 'model.prosite.waste.collection.search_read',
        params: [[['id', '=', bookingID]], null, null, null, ['job_number', 'client_name', 'contact_name', 'contact_phone_or_mobile', 'po_number', 'carrier', 'collection_method', 'vehicle_type', 'dockets', 'driver', 'booked_for', 'site_main_address', 'site_postal_code', 'status', 'notes', 'type', 'reason', 'reason_notes', 'print_name', 'signature', 'signature_file_id', 'supporting_documents.name', 'supporting_documents.data'], { web_user_session: rpc.getWebToken() }],
      }

      try {
        const response = await axios.post(path, payload, config)
        if (response) {
          if (response.data.error !== undefined) {
            return
          }

          if (response.data.result.length > 0) {
            vuexContext.commit('setCurrentBookingData', response.data.result[0])
            return
          }

          vuexContext.commit('setCheckedInStatus', 'No bookings')
        }
      } catch (Exception) {
        console.log('getDriverBooking catch:', Exception)
      }
    },

  },

}
